import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './settings/page-not-found/page-not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SkinnyBsshGuard } from './core/guards/skinnyBssh.guard';
import { SearchPathResolverService } from './core/services/resolver/search-path-resolver.service';
import { RedirectPathResolverService } from './core/services/resolver/redirect-path-resolver.service';
import { Constants } from './core/utilities/constants';
import { ResourceDeletedComponent } from './shared/resource-deleted/resource-deleted.component';
import { SwitchWorkgroupModalComponent } from './shared/modals/switch-workgroup/switch-workgroup-modal.component';
import { HttpErrorComponent } from './shared/http-error/http-error.component';
import { MultiAnalysisCodeFeaturesGuard } from '@app/run-planning/guards/multi-analysis-code-features.guard';

export const routes: Routes = [
  {
    path: 'runs',
    loadChildren: () => import('./runs/runs.module').then(m => m.RunsModule),
    canLoad: [AuthGuard, SkinnyBsshGuard],
    data: {
      pageTitlePrefix: 'Runs'
    }
  },
  {
    path: 'run',
    loadChildren: () => import('./runs/run-details.module').then(m => m.RunDetailsModule),
    canLoad: [AuthGuard, SkinnyBsshGuard],
    data: {
      pageTitlePrefix: 'Runs'
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canLoad: [AuthGuard, SkinnyBsshGuard],
    data: {
      pageTitlePrefix: 'Settings'
    }
  },
  {
    path: 'resources',
    loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule),
    canLoad: [AuthGuard, SkinnyBsshGuard],
    data: {
      pageTitlePrefix: 'Resources'
    }
  },
  {
    path: 'biosamples',
    loadChildren: () => import('./biosamples/biosamples.module').then(m => m.BiosamplesModule),
    canLoad: [AuthGuard, SkinnyBsshGuard],
    data: {
      pageTitlePrefix: 'Biosamples'
    }
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
    canLoad: [AuthGuard, SkinnyBsshGuard],
    data: {
      pageTitlePrefix: 'Projects'
    }
  },
 {
    path: 'apps',
    loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'datacentral',
    loadChildren: () => import('./demo-data/demo-data.module').then(m => m.DemoDataModule),
    canLoad: [SkinnyBsshGuard],
    data: {
      pageTitlePrefix: 'Demo Data'
    }
  },
  {
    path: 'analyses',
    loadChildren: () => import('./analyses/analyses.module').then(m => m.AnalysesModule),
    canLoad: [AuthGuard],
    data: {
      pageTitlePrefix: 'Analyses'
    }
  },
  {
    path: 'analyses',
    loadChildren: () => import('./analyses/analysis-details.module').then(m => m.AnalysisDetailsModule),
    canLoad: [AuthGuard, SkinnyBsshGuard],
    data: {
      pageTitlePrefix: 'Analyses'
    }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [AuthGuard, SkinnyBsshGuard],
    data: {
      pageTitlePrefix: 'Dashboard'
    }
  },
  {
    path: `s/:${Constants.InviteToken}`,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [AuthGuard],
    data: {
      pageTitlePrefix: 'Dashboard'
    }
  },
  {
    path: `g/:${Constants.InviteToken}`,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [AuthGuard],
    data: {
      pageTitlePrefix: 'Dashboard'
    }
  },
  {
    path: `sample`,
    loadChildren: () => import('./samples/sample-details.module').then(m => m.SampleDetailsModule),
    canLoad: [AuthGuard],
    data: {
      pageTitlePrefix: 'Sample Details'
    }
  },
  // Enable when pools pages are ready
  // {
  //   path: `pools`,
  //   loadChildren: () => import('./pools/pool-details.module').then(m => m.PoolDetailsModule),
  //   canLoad: [AuthGuard],
  //   data: {
  //     pageTitlePrefix: 'Pool Details'
  //   }
  // },
  {
    path: 'cloud-run-prep',
    loadChildren: () => import('./cloud-run-prep/cloud-run-prep.module').then(m => m.CloudRunPrepModule),
    canLoad: [AuthGuard],
    data: {
      pageTitlePrefix: 'Runs'
    }
  },
  {
    path: 'run-planning',
    canLoad: [AuthGuard],
    canActivate: [MultiAnalysisCodeFeaturesGuard],
    loadChildren: () => import('./run-planning/run-planning.module').then(m => m.RunPlanningModule),
    data: {
      pageTitlePrefix: 'Run Setup'
    }
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    canLoad: [AuthGuard],
    data: {
      pageTitlePrefix: 'Search'
    }
  },
  {
    path: Constants.RESOURCE_DELETED,
    component: ResourceDeletedComponent,
    data: {
      pageTitlePrefix: 'Page Gone'
    }
  },
  {
    path: Constants.SWITCH_WORKGROUP_ROUTE,
    component: SwitchWorkgroupModalComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitlePrefix: 'Switch Workgroup'
    }
  },
  {
    path: Constants.HTTP_ERROR_ROUTE,
    component: HttpErrorComponent,
    canActivate: [AuthGuard],
    data: {
      isTitleDynamic: true,
      pageTitlePrefix: 'Error'
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    resolve: {
      undefinedPath: RedirectPathResolverService
    },
    runGuardsAndResolvers: 'always',
    data: {
      pageTitlePrefix: 'Page Not Found'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,

    // Send user scrollbar to the top on route change.
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
  providers: [SearchPathResolverService,
    RedirectPathResolverService]
})
export class AppRoutingModule { }
