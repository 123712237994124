export class ErrorMessages {
    static readonly MISSING_RESOURCE_ID = 'Missing resource ID. Resource ID is needed in order to perform the requested operation.';
    static readonly MISSING_TRANSFEREE_EMAIL = 'Email ID of transferee is needed to transfer ownership';
    static readonly NO_PENDING_TRANSFER_REQUEST = 'There is no pending transfer request for the selected resource';
    static readonly UNSUPPORTED_RESOURCE_TYPE = 'Unsupported resource type = ';
    static readonly NOT_VALID_RESOURCE_URL = 'Not a valid resource url';
    static readonly MISSING_INVITATION_ID = 'Invitation ID missing. Invitation ID is needed to cancel a pending invitation.';
    static readonly PAGE_CONTEXT_INCONSISTENT_STATE = 'The previous url was not valid. Hence the page context service '
        + 'is not in a consistent state to provide required information';

    static RUN = {
        STILL_LOADING: 'Run is still loading.'
    };

    static RESOURCE_STATUS_NOT_ARCHIVABLE: 'The resource status is not archivable.';
    static readonly SHARE_ARCHIVED = 'This resource has been archived, and the sharing functionality is not available at this time.';
    static readonly NULL_RESOURCE = 'Resource can not be null';
    static readonly INVITATIONS_ERROR = 'Could not send invitations';
    static readonly COLLABORATOR_ERROR = 'Unable to get collaborators';
    static readonly GET_LINK_ERROR = 'Unable to share link';
    static readonly FAILED_TO_UPDATE_LANE_QC = 'Failed to change lane qc for one or more lanes';
    static readonly FAILED_TO_UPDATE_FASTQ_QC = 'Failed to change qc status for one or more datasets';
    static readonly FAILED_TO_ARCHIVE_RUNS = 'Failed to archive one or more runs';
    static readonly FAILED_TO_RESTORE_RUNS = 'Failed to restore one or more runs';
    static readonly FAILED_TO_ARCHIVE_DATASETS = 'Failed to archive one or more datasets';
    static readonly FAILED_TO_RESTORE_DATASETS = 'Failed to restore one or more datasets';
    static readonly FAILED_TO_UPDATE_ANALYSIS_DELIVERY_STATUS = 'Failed to change delivery status for one or more analyses';


    static DOWNLOAD = {
        NO_RESOURCE_ID_DOWNLOAD_URI: 'No resourceId specified to get Download Uri.',
        NO_RESOURCE_TYPE_DOWNLOAD_URI: 'No resourceType specified to get Download Uri.',
        NO_RESOURCE_ID_DOWNLOAD_INFO: 'No resourceId specified to get Download Info.',
        NO_RESOURCE_TYPE_DOWNLOAD_INFO: 'No resourceType specified to get Download Info.',
        DOWNLOAD_URI_IS_REQUIRED: 'DownloadUri is required to fetch download-configs',
        DOWNLOAD_CLIENT_UNSUPPORTED: `File download is supported only on Windows and Mac operating systems.` +
            `For downloads on Linux operating systems, use the BaseSpace Sequence Hub command-line interface.`
    };

    static RUN_EDIT = {
        NO_RUN_SPECIFIED: 'Editing Run name not allowed because no Run is specified.',
        NO_USER_SPECIFIED: 'Editing Run name not allowed because user id is not specified.',
        NOT_OWNER: 'Editing Run name not allowed because user is not the owner of the run.',
    };

    static PROJECT_EDIT = {
        NO_PROJECT_SPECIFIED: 'Editing Project name not allowed because no Project is specified.',
        NOT_OWNER: 'You must be the project owner to edit the project name.',
    };

    static ANALYSIS_EDIT = {
        NO_ANALYSIS_SPECIFIED: 'Editing Analysis name not allowed because no Analysis is specified.',
    };

    static ANALYSIS_CANCEL = {
        NO_ANALYSIS_SPECIFIED: 'Cancel Analysis not allowed because no Analysis is specified.',
        FAILED_TO_CANCEL_ANALYSIS :'Failed to cancel analysis'
    };

    static BIOSAMPLE_EDIT = {
        NO_BIOSAMPLE_SPECIFIED: 'Editing Biosample name not allowed because no BioSample is specified.',
    };

    static readonly MISSING_RESOURCE_ERROR = 'Resource is required to Trash';
    static readonly TRASH_RUN_STATUS_ERROR = 'Only runs that have the status of Complete, Stopped, ' +
        'Needs Attention, Upload Failed, Failed, or Timed Out can be moved to the trash.';
    static readonly TRASH_DATASET_ALERT = 'Deleting datasets will disable their use in any new or pending analysis.'
    static readonly TRASH_DATASET_NOT_OWNER_ALERT = 'You cannot delete one or more of these dataset files because you are not the owner.';
    static readonly TRASH_SAMPLE_NOT_OWNER_ALERT = 'You cannot delete one or more of these samples because you are not the owner.';
    static readonly TRASH_ANALYSES_STATUS_ERROR = 'Analyses with a status of Initializing, Running, Aborting, ' +
        'Queued for Analysis, and Payment Complete cannot be deleted.';

    static TRASH_PROJECT = {
        COLLABORATORS_ALERT: ' There is shared data associated with this project. ' +
        'Moving this run to the trash will remove collaborators\' access to that data.',
        OWNER_ALERT: 'Deleting a project will delete all associated data, including Samples & Analyses.',
        NOT_OWNER_ALERT: 'You are not the owner of this data. Moving this item to the trash will remove it from your account.',
        PROJECT_ALERT: 'Apps in your account that currently use this project may not function properly after this project is deleted.',
        PROJECT_BIOSAMPLE_ERROR: 'This project can\'t be deleted because it is the default project for Biosamples that contain data.',
        NULLIFY_BIOSAMPLE_DEFAULT_PROJECT_ALERT: 'This project is the default project for one or more Biosamples. ' +
        'Moving this project to the trash will remove the default project association. Certain apps may not ' + 
        'function properly when using Biosamples with no default project.',
        CHANGE_PROJECT_ALERT: 'To change the default project for the Biosamples, select the Biosamples section in the project, ' +
        'then select ' +
        `<strong>File</strong> > <strong>Edit</strong> > <strong>Biosample Default Project</strong>.`
    };

    static readonly CURRENT_RUN_NOT_AVAILABLE =
        'Can populate stats only for the current run. Looks like the current run is not loaded yet.';

    static readonly FAILED_TO_UPDATE_ANALYSIS_QC_STATUS = 'Failed to change qc status for one or more analyses';
    static RUN_INDEX_QC = {
        NO_RUN_SPECIFIED: 'Cannot fetch index summary as no run was specified.',
        INDEXING_UNAVAILABLE: 'Indexing QC unavailable.' +
            ' This can be either because the run\'s status is not \"Completed\"' +
            ' (this includes requeued runs), or because index metrics are not available.'
    };

    static readonly BIOSAMPLES_NOT_AVAILABLE = 'No matching records found.';
    static readonly NOT_AVAILABLE = 'No matching records found.';
    static readonly NO_VIEW_PERMISSION_ERROR = 'Details about this item belong to a project that you don\'t have permission to view. ' +
        'Contact the owner of the project and ask them to share it with you.';

    static readonly SAMPLESHEET_NOT_AVAILABLE = 'Could not retrieve sample sheet.';
    static readonly METRIC_STATS_NOT_AVAILABLE = 'SequencingStats not yet available.';
    static readonly METRICS_NOT_AVAILABLE = 'Metrics are unavailable. Please try again at a later time.';
    static readonly REQUEUE_STATUS_NOT_PENDING = 'You can only cancel requeues that are \'Pending\'.';

    static readonly BIOSAMPLE_UPLOAD = {
        FILE_NOT_PROVIDED: 'No file provided for biosample workflow upload',
        getInvalidFileExtensionMsg: (validExtensions: string[]) => {
            return `Invalid File extension. Valid File extensions are ${validExtensions.toString()}.`;
        }
    }
    static readonly LIBRARY_REQUEUE_ERROR = {
        INVALID_LIBRARY_STATUS: 'You can only requeue a library if it is in an <strong>Active</strong> state.',
        CANCELLED_BIOSAMPLE: 'To requeue a library, the associated biosample\'s status must be ' +
            '<strong>Active</strong> or <strong>Missing Yield</strong >.',
        INVALID_PREP_REQUEST: 'To requeue a library, its prep request must be <strong>Active</strong> or <strong>MissingYield.</strong>'
    };

    static readonly DEMO_DATA_NOT_AVAILABLE = 'Could not find any matching datasets. Try changing your search criteria.';
    static readonly ARCHIVED_RUN_CONTENT_NOT_AVAILABLE = 'This content is not available for archived runs.';
    static readonly CURRENT_BIOSAMPLE_NOT_AVAILABLE =
        'Looks like the current biosample is not loaded yet.';
    static readonly RUN_CONTENT_NOT_AVAILABLE_TRANSFER_IN_PROGRESS = 'This content is not available until the run transfer is completed.';
    static readonly PROJECT_CONTENT_NOT_AVAILABLE_TRANSFER_IN_PROGRESS = 'This content is not available until the project transfer is completed.';
    static readonly PROJECT_ANALYSES_NOT_AVAILABLE_TRANSFER_IN_PROGRESS = 'These analyses are not available until the project transfer is completed.';
    static readonly PROJECT_CONTENT_NOT_AVAILABLE_TRASH_RESTORE_IN_PROGRESS = 'This content is not available until the project restore from trash is completed.';
    static readonly PROJECT_ANALYSES_NOT_AVAILABLE_TRASH_RESTORE_IN_PROGRESS = 'These analyses are not available until the project restore from trash is completed.';
    static readonly POOL_LIBRARIES_NOT_AVAILABLE = 'Pool libraries content is not available.';

    static readonly SAMPLE_SHEET_NEEDS_ATTENTION = 'Sample sheet needs attention.';

    static readonly MICROARRAY_ANALYSIS_SETUP = {
      APP_NOT_FOUND: 'The microarray app was not found',
      APP_EULA_NOT_ACCEPTED: 'User did not accept the EULA.',
      IMPORT_IDATS_DUPLICATE_IDATS: 'Multiple IDAT files with the same name were selected. Please reselect IDAT files with unique names. ' +
                                    'The following are the duplicate IDAT files.',
      IMPORT_IDATS_MISSING_IDATS: 'Incomplete IDAT pairs were selected. Please reselect green and red IDATs for every sample. ' +
                                  'The following are the missing IDAT files.',
      IMPORT_IDATS_INCORRECTLY_NAMED: 'Incorrectly named IDATs were selected. ' +
        'Please reselect IDATs that follow the naming convention "{beadChip name}_{section name}_{channel}.idat". ' +
        'The following are the incorrectly named IDAT files.',
      IMPORT_IDATS_IMPORTED_FROM_PREVIOUS_SESSION: 'IDAT files that were already imported for the current analysis were selected ' +
        'and are shown below. Please reselect IDATs that have not already been imported for the current analysis. ',
      IMPORT_IDATS_EXISTING_SAMPLES: 'A pair of IDAT files already exists for the following samples. ' +
        'Leave the checkbox checked to skip importing duplicates, or uncheck it to replace existing IDAT files for future analyses.',
      NO_IDATS_TO_IMPORT_EXCLUDING_DUPLICATE: 'No IDAT files to import after excluding duplicate IDAT files for samples that already have imported IDAT files.'
    };

    // Any hyperlinks in the messages should have a css class of 'http-error-message__link' for them to get the universal hyperlink style
    static readonly HTTP_ERROR_MESSAGES = {
        UNPLANNED_503: {
            MAIN: 'Sequence Hub is experiencing technical difficulties',
            SUMMARY:
            `We're working on a solution and hope to be back online soon. We apologize for the inconvenience.`,
            DETAIL:
            `
            You can view system status and sign up for notifications at
            <a class="http-error-message__link" href="https://status.illumina.com/" target="_blank">status.illumina.com.</a>
            <br>
            If you have questions or comments, please review our
            <a class="http-error-message__link" 
            target="_blank" 
            href="https://knowledge.illumina.com/software/cloud-software/software-cloud-software-troubleshooting-list/000007381">
            Support Resources
            </a>`,
            PAGE_TITLE: 'Outage'
        },
        PLANNED_503: {
            MAIN: 'Sequence Hub is temporarily down for maintenance',
            SUMMARY: (startTime: string, endTime: string) =>
            `We expect to be back online by ${endTime}. Sequencing run uploads that are in progress will resume when service is restored.`,
            DETAIL: (startTime: string, endTime: string) =>
            `
            You can view system status and sign up for notifications at
            <a class="http-error-message__link" href="https://status.illumina.com/" target="_blank">status.illumina.com.</a>
            <br>
            If you have questions or comments, please review our
            <a class="http-error-message__link" 
            target="_blank" 
            href="https://knowledge.illumina.com/software/cloud-software/software-cloud-software-troubleshooting-list/000007381">
            Support Resources
            </a>
            <br>
            Updated ${startTime}`,
            PAGE_TITLE: 'Outage'
        },
        RESOURCE_403: {
            MAIN: `You don't have access to the requested page`,
            SUMMARY: 'This may be the result of transfer or unsharing.',
            DETAIL:
            `
            Try going back to the <a class="http-error-message__link" href="/dashboard">dashboard.</a>
            <br/>
            Or, go back to <a href="https://www.illumina.com" target="_blank" class="http-error-message__link">illumina.com</a>
            <br/>
            Or, try <a href="https://my.illumina.com/order-management" target="_blank" class="http-error-message__link">My Account</a>`,
            PAGE_TITLE: 'Forbidden'
        },
        RESOURCE_404: {
            MAIN: `We couldn't find the page you requested`,
            SUMMARY: ``,
            DETAIL: `
            Try <a class="http-error-message__link" href="/dashboard">returning to the dashboard</a> or using the search feature`,
            PAGE_TITLE: 'NotFound'
        }
    };
    static readonly BIOSAMPLE_LOCKED = (biosampleName: string) =>
    `The biosample ${biosampleName} is locked because it may contain FASTQ datasets` +
    ' from multiple sources aggregated under the same library prep kit.' +
    ' To unlock the biosample, review the FASTQ datasets to ensure accurate data aggregation,' +
    ' and then unlock the biosample by selecting Status > Unlock > Biosample in the action toolbar.' +
    ' If the biosample contains datasets that should not be included, change their status to QC Failed' +
    ' first and then unlock the biosample.'
}
